<style scoped>
.loading{
  display         : flex;
  justify-content : center;
  margin          : 25% 0;
}

.loading .dot{
  border-radius : 65%;
  height        : 1em;
  margin        : 0.8em;
  position      : relative;
  width         : 1em;
}

.loading .dot::before{
  animation     : wave 2s ease-out infinite;
  background    : inherit;
  border-radius : inherit;
  content       : '';
  height        : 100%;
  position      : absolute;
  width         : 100%;
}

.loading .dot:nth-child(1){
  background : #bfbfbf;
}

.loading .dot:nth-child(1)::before{
  animation-delay : 0.2s;
}

.loading .dot:nth-child(2){
  background : #bfbfbf;
}

.loading .dot:nth-child(2)::before{
  animation-delay : 0.4s;
}

.loading .dot:nth-child(3){
  background : #bfbfbf;
}

.loading .dot:nth-child(3)::before{
  animation-delay : 0.6s;
}

.loading .dot:nth-child(4){
  background : #bfbfbf;
}

.loading .dot:nth-child(4)::before{
  animation-delay : 0.8s;
}

.loading .dot:nth-child(5){
  background : #bfbfbf;
}

.loading .dot:nth-child(5)::before{
  animation-delay : 1s;
}

@keyframes wave{
  50%, 75%{
	 transform : scale(2);
  }
  80%, 100%{
	 opacity : 0;
  }
}

.absolute{
  position: absolute;
  z-index: 1;
  height: auto;
  width: calc(100% + 30px);
  top: 0;
  bottom: 0;
  background-color: #f8f8f8c7;
  padding: 25% 0;
  margin : 0 -15px;
}
</style>

<template>
  <div class="loading" :class="loadingWithDataClass">
	 <div class="dot"></div>
	 <div class="dot"></div>
	 <div class="dot"></div>
	 <div class="dot"></div>
	 <div class="dot"></div>
  </div>
</template>

<script>
export default {
  props:{
	 loadingWithData: false
  },
  computed: {
	 loadingWithDataClass() {
		return this.loadingWithData && 'absolute';
	 }
  }
}
</script>
