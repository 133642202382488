import Preloader from '@/components/Preloader'
import { viewLogger } from '@/lib/utils'
import { Hotel } from '@/services/Hotel'
import { User } from '@/services/User'
import axios from 'axios'
import { BootstrapVue, IconsPlugin, VBToggle, VBTogglePlugin } from 'bootstrap-vue'
import IdleVue from 'idle-vue'
import Vue from 'vue'
import { Fragment } from 'vue-frag'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import vSelect from 'vue-select'
import App from './App.vue'
import router from './router'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.prototype.$axios = axios

// Make BootstrapVue available throughout your project
Vue.use( BootstrapVue )
// Optionally install the BootstrapVue icon components plugin
Vue.use( IconsPlugin )
Vue.use( VBTogglePlugin )
Vue.use( VBToggle )

Vue.component( 'v-select', vSelect )
Vue.component( 'preloader', Preloader )
Vue.component('Fragment', Fragment)

Vue.config.productionTip = false
var ipformat             = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/

let env_server_api = process.env.VUE_APP_SERVER_API
let protocol       = process.env.VUE_APP_SERVER_API_PROTOCOL
    ? process.env.VUE_APP_SERVER_API_PROTOCOL
    : 'https://'

if ( !env_server_api ) {
    env_server_api = 'vitrina.stayday.com'
}
else {
    if ( env_server_api.match( ipformat ) ) {
        env_server_api = 'http://' + process.env.VUE_APP_SERVER_API + ':5005'
    }
    else if ( env_server_api === 'localhost' ) {
        env_server_api = 'http://' + process.env.VUE_APP_SERVER_API + ':5005'
    }
    else {
        env_server_api = protocol + process.env.VUE_APP_SERVER_API + ''
    }
}

let version = process.env.VUE_APP_VERSION
    ? process.env.VUE_APP_VERSION
    : '0.0.2'

let hostClient = process.env.VUE_APP_CLIENT_HOST

Vue.prototype.$server_api = env_server_api

const eventsHub = new Vue()
Vue.use( IdleVue, {
    eventEmitter: eventsHub,
    idleTime: 1000 * 60 * 60
} )

export const EventBus = new Vue()

const app = new Vue( {
    router,
    onIdle() {
        console.log( 'idle' )
        this.user = null
        User.clear()
        this.$emit( 'user:logout' )
        this.$router.push( '/login' )
    },
    onActive() {
        // console.log('active')
    },
    data: {
        host: env_server_api,
        hostClient: hostClient,
        protocol: protocol,
        ping: null,
        socket: null,
        toggled: false,
        user: null,
        browsers: [],
        accounts: [],
        users: [],
        alerts: [],
        videos: [],
        proxies: [],
        progress: {},
        hotel: null,
        currency: '₽',
        userRoles: [],
        userGroups: User.getUserGroups(),
        version: version
    },
    methods: {
        alert( info ) {
            info.date = new Date().getTime() + Math.floor( Math.random() * 1000 )
            this.alerts.unshift( info )
            
            setTimeout( () => {
                this.alerts.shift()
            }, 5000 )
        },
        alertClose( info ) {
            this.alerts = this.alerts.filter( obj => obj.date !== info.date )
        },
        getHeaders() {
            return {
                'Authorization': 'Bearer ' + localStorage.getItem( 'token' ),
                'hotel': localStorage.getItem( 'hotel' )
            }
        },
        getHeadersJSON() {
            return {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem( 'token' ),
                'hotel': localStorage.getItem( 'hotel' )
            }
        },
        getHeadersMultipart() {
            return {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem( 'token' ),
                'hotel': localStorage.getItem( 'hotel' )
            }
        },
        selectedHotel() {
            return Hotel.getLocalHotel()
        }
    },
    mounted() {
        let toggled = localStorage.getItem( 'toggle' )
        if ( toggled ) {
            this.toggled = JSON.parse( toggled )
        }
        this.user  = User.getLocalUser()
        this.hotel = Hotel.getLocalHotel()
    },
    computed: {
        isAdministrator() {
            return this.user && this.user.group === 'administrator'
        },
        isManager() {
            return this.user && this.user.group === 'manager'
        },
        isVendor() {
            return this.user && this.user.group === 'vendor'
        },
        isSupport() {
            return this.user && this.user.group === 'support'
        },
        isUser() {
            return this.user && this.user.group === 'employee'
        }
    },
    beforeMount() {
        viewLogger( true, 'Main', this )
    },
    beforeDestroy() {
        viewLogger( false, 'Main', this )
    },
    render: h => h( App )
} )
app.$mount( '#app' )
