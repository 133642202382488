import { ApiClient } from './Api'

/**
 * Класс `HotelClient` для управления отелями через API.
 *
 * Методы:
 * - `new(hotelData: Object): Promise<Object|null>`: Создает новый объект отеля.
 * - `get(params: { id: number }, options?: { hotel?: boolean, cache?: boolean }): Promise<Object|null>`: Получает
 * данные отеля по его ID.
 * - `all(options?: { hotel?: boolean, cache?: boolean }): Promise<Object|null>`: Получает список всех отелей.
 * - `update(hotelData: Object): Promise<Object|null>`: Обновляет данные отеля.
 * - `delete(hotelData: Object): Promise<Object|null>`: Удаляет отель.
 * - `clear(): void`: Очищает данные отеля из локального хранилища.
 * - `save(hotel: string): void`: Сохраняет данные отеля в локальное хранилище.
 * - `getLocalHotel(): string|null`: Получает данные отеля из локального хранилища.
 * - `getTypeName(type: string): string`: Возвращает название типа объекта по его типу.
 * - `getHotelById(params: { hotels: Array<Object>, id: number }): Object`: Ищет отель по его идентификатору в массиве
 * отелей. Приватные поля:
 * - `#api: ApiClient`: Экземпляр клиента API для выполнения HTTP-запросов.
 */
class HotelClient {
    #api = new ApiClient()
    
    /**
     * Создает новый объект отеля.
     * @param {Object} hotelData - Данные для нового отеля.
     * @returns {Promise<Object|null>} Промис с данными созданного отеля или null в случае ошибки.
     */
    async new( hotelData ) {
        const headers = this.#api.getHeaders()
        return new Promise( ( resolve, reject ) => {
            if ( !hotelData ) {
                console.error( 'Ошибка при создании объекта. Не передан hotelData' )
                return resolve( null )
            }
            
            this.#api.fetch( {
                    method: 'post',
                    url: `/hotels/new`,
                    data: hotelData,
                    headers
                } )
                .then( ( { status, data } ) => {
                    if ( status ) {
                        return resolve( data )
                    }
                    return resolve( null )
                } )
                .catch( e => {
                    console.error( 'Ошибка при создании объекта', e )
                    return reject( e.data )
                } )
        } )
    }
    
    /**
     * Получает данные отеля по его ID.
     * @param {Object} params - Параметры для запроса.
     * @param {number} params.id - ID отеля.
     * @param {Object} [options={ hotel: true, cache: true }] - Опции для запроса.
     * @param {boolean} [options.hotel=true] - Включить в header hotel.
     * @param {boolean} [options.cache=true] - Включить кэширование.
     * @returns {Promise<Object|null>} Промис с данными отеля или null в случае ошибки.
     */
    async get( params = { id }, options = { hotel: true, cache: true } ) {
        const headers = this.#api.getHeaders()
        return new Promise( ( resolve ) => {
            if ( !params.id ) {
                console.error( 'Ошибка при получении данных объекта. Не передан id' )
                return resolve( null )
            }
            
            this.#api.fetch( {
                    method: 'get',
                    url: `/hotels/${ params.id }`,
                    headers
                }, options )
                .then( ( { status, data } ) => {
                    if ( status ) {
                        return resolve( data )
                    }
                    return resolve( null )
                } )
                .catch( e => {
                    console.error( 'Ошибка при получении данных объекта', e )
                    return resolve( null )
                } )
        } )
    }
    
    /**
     * Получает список всех отелей.
     * @param {Object} [options={ hotel: true, cache: true }] - Опции для запроса.
     * @param {boolean} [options.hotel=true] - Включить в header hotel.
     * @param {boolean} [options.cache=true] - Включить кэширование.
     * @returns {Promise<Object|null>} Промис с данными всех отелей или null в случае ошибки.
     */
    async all( options = { hotel: true, cache: true } ) {
        const headers = this.#api.getHeaders()
        return new Promise( ( resolve ) => {
            this.#api.fetch( {
                    method: 'get',
                    url: `/hotels`,
                    headers
                }, options )
                .then( ( { status, data } ) => {
                    if ( status ) {
                        return resolve( data )
                    }
                    return resolve( null )
                } )
                .catch( e => {
                    console.error( 'Ошибка при получении данных объектов', e )
                    return resolve( null )
                } )
        } )
    }
    
    /**
     * Обновляет данные отеля.
     * @param {Object} hotelData - Данные отеля для обновления.
     * @returns {Promise<Object|null>} Промис с обновленными данными отеля или null в случае ошибки.
     */
    async update( hotelData ) {
        const headers = this.#api.getHeaders()
        return new Promise( ( resolve, reject ) => {
            if ( !hotelData ) {
                console.error( 'Ошибка при обновлении данных объекта. Не передан hotelData' )
                return resolve( null )
            }
            
            this.#api.fetch( {
                    method: 'post',
                    url: `/hotels/update`,
                    data: hotelData,
                    headers
                } )
                .then( ( { status, data } ) => {
                    if ( status ) {
                        return resolve( data )
                    }
                    return resolve( null )
                } )
                .catch( e => {
                    console.error( `Ошибка при обновлении данных объекта по id:${ hotelData.id }`, e )
                    return reject( e.data )
                } )
        } )
    }
    
    /**
     * Удаляет отель.
     * @param {Object} hotelData - Данные отеля для удаления.
     * @returns {Promise<Object|null>} Промис с результатом удаления отеля или null в случае ошибки.
     */
    async delete( hotelData ) {
        const headers = this.#api.getHeaders()
        return new Promise( ( resolve, reject ) => {
            if ( !hotelData ) {
                console.error( 'Ошибка при удалении. Не передан hotelData' )
                return resolve( null )
            }
            
            this.#api.fetch( {
                    method: 'post',
                    url: `/hotels/delete`,
                    data: hotelData,
                    headers
                } )
                .then( ( { status, data } ) => {
                    if ( status ) {
                        return resolve( data )
                    }
                    return resolve( null )
                } )
                .catch( e => {
                    console.error( `Ошибка при удалении объекта по id:${ hotelData.id }`, e )
                    return reject( e.data )
                } )
        } )
    }
    
    /**
     * Очищает данные отеля из локального хранилища.
     * @returns {void}
     */
    clear() {
        localStorage.removeItem( 'hotel' )
    }
    
    /**
     * Сохраняет данные отеля в локальное хранилище.
     * @param {string} hotel - Данные отеля для сохранения.
     * @returns {void}
     */
    save( hotel ) {
        if ( hotel ) {
            localStorage.setItem( 'hotel', hotel )
        }
    }
    
    /**
     * Получает данные отеля из локального хранилища.
     * @returns {number|null} Данные отеля или null, если данные отсутствуют.
     */
    getLocalHotel() {
        const hotel = localStorage.getItem( 'hotel' )
        return hotel ? Number( hotel ) : null
    }
    
    /**
     * Возвращает название типа объекта по его типу.
     * @param {string} type - Тип объекта.
     * @returns {string} Название типа объекта или "Еще не выбран", если тип не определен.
     */
    getTypeName( type ) {
        const names = {
            obj: 'Объект',
            mplace: 'Маркетплейс'
        }
        
        if ( type in names ) {
            return names[ type ]
        }
        
        return 'Еще не выбран'
    }
    
    /**
     * Ищет отель по его идентификатору в массиве отелей.
     * @param {Object} params - Параметры для поиска отеля.
     * @param {Array} params.hotels - Массив отелей.
     * @param {number} params.id - Идентификатор отеля, который нужно найти.
     * @returns {Object} Объект отеля с указанным идентификатором или пустой объект, если отель не найден.
     */
    getHotelById( { hotels = [], id = 0 } ) {
        return hotels.find( el => el.id === Number( id ) ) || {}
    }
}

/**
 * Экземпляр класса `HotelClient` для управления отелями через API.
 *
 * @type {HotelClient}
 *
 * Методы:
 * - `new(hotelData: Object): Promise<Object|null>`: Создает новый объект отеля.
 * - `get(params: { id: number }, options?: { hotel?: boolean, cache?: boolean }): Promise<Object|null>`: Получает
 * данные отеля по его ID.
 * - `all(options?: { hotel?: boolean, cache?: boolean }): Promise<Object|null>`: Получает список всех отелей.
 * - `update(hotelData: Object): Promise<Object|null>`: Обновляет данные отеля.
 * - `delete(hotelData: Object): Promise<Object|null>`: Удаляет отель.
 * - `clear(): void`: Очищает данные отеля из локального хранилища.
 * - `save(hotel: string): void`: Сохраняет данные отеля в локальное хранилище.
 * - `getLocalHotel(): string|null`: Получает данные отеля из локального хранилища.
 * - `getTypeName(type: string): string`: Возвращает название типа объекта по его типу.
 *
 * Приватные поля:
 * - `#api: ApiClient`: Экземпляр клиента API для выполнения HTTP-запросов.
 */
export const Hotel = new HotelClient()
