import { ApiClient } from './Api'

/**
 * Класс `UserClient` предоставляет методы для выполнения CRUD операций над пользователями через API.
 *
 * Приватные поля:
 * - `#api`: Экземпляр ApiClient для выполнения HTTP-запросов.
 * - `#userGroups`: Список стандартных групп пользователей.
 * - `#userGroupsSuper`: Список суперпользовательских групп.
 *
 * Методы:
 * - `get(params: { id: number }, options?: { hotel?: boolean, cache?: boolean }): Promise<Object|null>`:
 *   Получает данные пользователя по его ID.
 * - `all(params?: { page?: number, size?: number }, options?: { hotel?: boolean, cache?: boolean }):
 * Promise<Array<Object>|null>`: Получает список пользователей с поддержкой пагинации.
 * - `new(userData: Object): Promise<Object|null>`:
 *   Создает нового пользователя.
 * - `update(userData: Object): Promise<Object|null>`:
 *   Обновляет данные существующего пользователя.
 * - `delete(userData: Object): Promise<Object|null>`:
 *   Удаляет пользователя.
 * - `clear(): void`:
 *   Очищает данные пользователя из localStorage.
 * - `save(user: Object): void`:
 *   Сохраняет данные пользователя в localStorage.
 * - `getLocalUser(): Object|null`:
 *   Получает данные пользователя из localStorage.
 * - `getUserGroups(withSuper?: boolean): Array<{ name: string, value: string }>`:
 *   Возвращает список групп пользователей. Если `withSuper` равно `true`, добавляет суперпользовательские группы.
 * - `getUserGroupName(user: Object, withSuper?: boolean): string`:
 *   Возвращает название группы пользователя по его объекту. Если `withSuper` равно `true`, включает
 * суперпользовательские группы.
 * - `getAdminUsersHotel(users: Array<Object>, hotelId: number): Array<Object>`:
 *   Фильтрует пользователей, оставляя только администраторов, активных пользователей и пользователей, связанных с
 * указанным отелем.
 * - `getUserHotelById(users: Array<Object>, userId: number): Object`:
 *   Возвращает объект пользователя по его ID или пустой объект, если пользователь не найден.
 * - `getUserPhone(user: Object): string`:
 *   Форматирует и возвращает номер телефона пользователя в международном формате.
 */
class UserClient {
    // Приватное поле, содержащее экземпляр ApiClient для выполнения запросов к API
    #api = new ApiClient()
    
    // Приватное поле, содержащее список стандартных групп пользователей
    #userGroups = [
        { name: 'Управляющий', value: 'administrator' },
        { name: 'Администратор', value: 'manager' },
        { name: 'Сотрудник', value: 'employee' }
    ]
    
    // Приватное поле, содержащее список суперпользовательских групп
    #userGroupsSuper = [
        { name: 'Вендор', value: 'vendor' },
        { name: 'Поддержка', value: 'support' }
    ]
    
    /**
     * Получает данные пользователя по его ID.
     *
     * @param {Object} params - Параметры запроса.
     * @param {number} params.id - Идентификатор пользователя.
     * @param {Object} [options] - Дополнительные опции запроса.
     * @param {boolean} [options.hotel=true] - Включить ли параметр `hotel` в заголовки запроса.
     * @param {boolean} [options.cache=true] - Использовать ли кэширование.
     * @returns {Promise<Object|null>} Промис с данными пользователя или `null` в случае ошибки.
     */
    async get(params = { id }, options = { hotel: true, cache: true }) {
        const headers = this.#api.getHeaders()
        return new Promise((resolve) => {
            if (!params.id) {
                console.error(`Ошибка при получении данных пользователя. Не передан id`)
                return resolve(null)
            }
            
            this.#api.fetch({
                    method: 'get',
                    url: `/users/${params.id}`,
                    headers
                }, options)
                .then(({ status, data }) => {
                    if (status) {
                        this.save(data)
                        return resolve(data)
                    }
                    return resolve(null)
                })
                .catch(e => {
                    console.error(`Ошибка при получении данных пользователя по id:${params.id}`, e)
                    return resolve(null)
                })
        })
    }
    
    /**
     * Получает список пользователей с поддержкой пагинации.
     *
     * @param {Object} [params] - Параметры запроса.
     * @param {number} [params.page=1] - Номер страницы (по умолчанию 1).
     * @param {number} [params.size=10] - Размер страницы (по умолчанию 10).
     * @param {Object} [options] - Дополнительные опции запроса.
     * @param {boolean} [options.hotel=true] - Включить ли параметр `hotel` в заголовки запроса.
     * @param {boolean} [options.cache=true] - Использовать ли кэширование.
     * @returns {Promise<Array<Object>|null>} Промис с массивом данных пользователей или `null` в случае ошибки.
     */
    async all(params = { page: 1, size: 10 }, options = { hotel: true, cache: true }) {
        params = { page: 1, size: 10, ...params }
        const query = new URLSearchParams(params).toString()
        const headers = this.#api.getHeaders()
        return new Promise((resolve) => {
            this.#api.fetch({
                    method: 'get',
                    url: `/users?${query}`,
                    headers
                }, options)
                .then(({ status, data }) => {
                    if (status) {
                        return resolve(data)
                    }
                    return resolve(null)
                })
                .catch(e => {
                    console.error(`Ошибка при получении данных пользователей`, e)
                    return resolve(null)
                })
        })
    }
    
    /**
     * Создает нового пользователя.
     *
     * @param {Object} userData - Данные пользователя для создания.
     * @returns {Promise<Object|null>} Промис с данными созданного пользователя или `null` в случае ошибки.
     */
    async new(userData) {
        const headers = this.#api.getHeaders()
        return new Promise((resolve, reject) => {
            if (!userData) {
                console.error(`Ошибка при создании пользователя. Не передан userData`)
                return resolve(null)
            }
            
            this.#api.fetch({
                    method: 'post',
                    url: `/users/new`,
                    data: userData,
                    headers
                })
                .then(({ status, data }) => {
                    if (status) {
                        return resolve(data)
                    }
                    return resolve(null)
                })
                .catch(e => {
                    console.error(`Ошибка при создании пользователя`, e)
                    return reject(e.data)
                })
        })
    }
    
    /**
     * Обновляет данные существующего пользователя.
     *
     * @param {Object} userData - Данные пользователя для обновления.
     * @returns {Promise<Object|null>} Промис с обновленными данными пользователя или `null` в случае ошибки.
     */
    async update(userData) {
        const headers = this.#api.getHeaders()
        return new Promise((resolve, reject) => {
            if (!userData) {
                console.error(`Ошибка при обновлении данных пользователя. Не передан userData`)
                return resolve(null)
            }
            
            this.#api.fetch({
                    method: 'post',
                    url: `/users/update`,
                    data: userData,
                    headers
                })
                .then(({ status, data }) => {
                    if (status) {
                        return resolve(data)
                    }
                    return resolve(null)
                })
                .catch(e => {
                    console.error(`Ошибка при обновлении данных пользователя по id:${userData.id}`, e)
                    return reject(e.data)
                })
        })
    }
    
    /**
     * Удаляет пользователя.
     *
     * @param {Object} userData - Данные пользователя для удаления.
     * @returns {Promise<Object|null>} Промис с ответом сервера или `null` в случае ошибки.
     */
    async delete(userData) {
        const headers = this.#api.getHeaders()
        return new Promise((resolve, reject) => {
            if (!userData) {
                console.error(`Ошибка при удалении. Не передан userData`)
                return resolve(null)
            }
            
            this.#api.fetch({
                    method: 'post',
                    url: `/users/delete`,
                    data: userData,
                    headers
                })
                .then(({ status, data }) => {
                    if (status) {
                        return resolve(data)
                    }
                    return resolve(null)
                })
                .catch(e => {
                    console.error(`Ошибка при удалении пользователя по id:${userData.id}`, e)
                    return reject(e.data)
                })
        })
    }
    
    /**
     * Очищает данные пользователя из localStorage.
     */
    clear() {
        localStorage.removeItem('user')
    }
    
    /**
     * Сохраняет данные пользователя в localStorage.
     *
     * @param {Object} user - Данные пользователя для сохранения.
     */
    save(user) {
        localStorage.setItem('user', JSON.stringify(user))
    }
    
    /**
     * Получает данные пользователя из localStorage.
     *
     * @returns {Object|null} Данные пользователя или `null`, если данных нет.
     */
    getLocalUser() {
        const data = localStorage.getItem('user')
        return data ? JSON.parse(data) : null
    }
    
    /**
     * Возвращает список групп пользователей.
     *
     * @param {boolean} [withSuper=false] - Включить ли суперпользовательские группы.
     * @returns {Array<{ name: string, value: string }>} Массив объектов с именами и значениями групп пользователей.
     */
    getUserGroups(withSuper = false) {
        return withSuper ? [...this.#userGroups, ...this.#userGroupsSuper] : this.#userGroups
    }
    
    /**
     * Возвращает название группы пользователя.
     *
     * @param {Object} user - Объект пользователя.
     * @param {boolean} [withSuper=false] - Включить ли суперпользовательские группы.
     * @returns {string} Название группы пользователя.
     */
    getUserGroupName(user, withSuper = false) {
        const groups = this.getUserGroups(withSuper)
        const found = groups.find(g => g.value === user.group)
        return found ? found.name : ''
    }
    
    /**
     * Фильтрует список пользователей, оставляя только администраторов и активных пользователей, связанных с указанным
     * отелем.
     *
     * @param {Array<Object>} users - Массив объектов пользователей.
     * @param {number} hotelId - Идентификатор отеля.
     * @returns {Array<Object>} Массив отфильтрованных пользователей.
     */
    getAdminUsersHotel(users, hotelId) {
        return users.filter(user =>
            user.group === 'administrator' &&
            Boolean(user.active) === true &&
            (hotelId ? Number(user.hotel_id) === Number(hotelId) : true)
        )
    }
    
    /**
     * Возвращает объект пользователя по его ID.
     *
     * @param {Array<Object>} users - Массив объектов пользователей.
     * @param {number} userId - Идентификатор пользователя.
     * @returns {Object} Объект пользователя или пустой объект, если пользователь не найден.
     */
    getUserHotelById(users, userId) {
        return users.find(u => Number(u.id)=== Number(userId)) || {}
    }
    
    /**
     * Форматирует номер телефона пользователя в международном формате.
     *
     * @param {Object} user - Объект пользователя.
     * @returns {string} Форматированный номер телефона пользователя.
     */
    getUserPhone(user) {
        const phone = user?.phone?.replace(/[^\d]/g, '')
        return phone ? `+${phone}` : ''
    }
}

/**
 * Экземпляр класса `UserClient` для выполнения CRUD операций над пользователями через API.
 *
 * @type {UserClient}
 *
 * Методы:
 * - `get(params: { id: number }, options?: { hotel?: boolean, cache?: boolean }): Promise<Object|null>`:
 *   Получает данные пользователя по его ID.
 * - `all(params?: { page?: number, size?: number }, options?: { hotel?: boolean, cache?: boolean }):
 *     Promise<Array<Object>|null>`: Получает список пользователей с поддержкой пагинации.
 * - `new(userData: Object): Promise<Object|null>`:
 *   Создает нового пользователя.
 * - `update(userData: Object): Promise<Object|null>`:
 *   Обновляет данные существующего пользователя.
 * - `delete(userData: Object): Promise<Object|null>`:
 *   Удаляет пользователя.
 * - `clear(): void`:
 *   Очищает данные пользователя из localStorage.
 * - `save(user: Object): void`:
 *   Сохраняет данные пользователя в localStorage.
 * - `getLocalUser(): Object|null`:
 *   Получает данные пользователя из localStorage.
 * - `getUserGroups(withSuper?: boolean): Array<{ name: string, value: string }>`:
 *   Возвращает список групп пользователей. Если `withSuper` равно `true`, добавляет суперпользовательские группы.
 * - `getUserGroupName(user: Object, withSuper?: boolean): string`:
 *   Возвращает название группы пользователя по его объекту. Если `withSuper` равно `true`, включает
 *     суперпользовательские группы.
 * - `getAdminUsersHotel(users: Array<Object>, hotelId: number): Array<Object>`:
 *   Фильтрует пользователей, оставляя только администраторов, активных пользователей и пользователей, связанных с
 *     указанным отелем.
 * - `getUserHotelById(users: Array<Object>, userId: number): Object`:
 *   Возвращает объект пользователя по его ID или пустой объект, если пользователь не найден.
 * - `getUserPhone(user: Object): string`:
 *   Форматирует и возвращает номер телефона пользователя в международном формате.
 */
export const User = new UserClient()
