/**
 * Класс `ApiCacheClass` для управления кэшированием данных в памяти с использованием `Map`.
 * Предназначен для временного хранения данных, связанных с API-запросами, с возможностью указания времени жизни
 * (TTL) для каждого элемента кэша.
 *
 * Методы:
 * - `get(key: string): any|null`:
 *   Получает значение из кэша по заданному ключу, если элемент не просрочен. Возвращает `null`, если элемент
 *   отсутствует или его срок годности истек.
 * - `set(key: string, value: any): void`:
 *   Добавляет новый элемент в кэш с указанным ключом и значением, устанавливая срок его годности (TTL).
 *
 * Приватные поля:
 * - `#ttl: number`: Время жизни элемента кэша в миллисекундах (по умолчанию 30 секунд).
 * - `#cache: Map<string, { value: any, expire: number }>`: Объект `Map` для хранения кэшированных данных.
 *
 * Конструктор:
 * - Инициализирует экземпляр `ApiCacheClass`, принимая необязательный объект `Map` для инициализации кэша.
 *   Если объект не передан, создается новый `Map`.
 */
export class ApiCacheClass {
    #ttl   = 100000
    #cache = window._CACHE_ = new Map()
    
    /**
     * Конструктор класса `ApiCacheClass`.
     * Инициализирует кэш новым объектом `Map` или переданным объектом `map`.
     *
     * @param {Map<string, { value: any, expire: number }>} [map=null] - Опциональный объект `Map` для инициализации
     * кэша. Если не передан, создается новый `Map`.
     */
    constructor( map = null ) {
        if ( map ) {
            this.#cache = map
        }
    }
    
    /**
     * Метод для получения значения из кэша.
     * Проверяет наличие элемента в кэше по указанному ключу и возвращает его значение, если срок годности элемента
     * еще не истек.
     *
     * @param {string} key - Ключ элемента в кэше.
     * @returns {any|null} - Возвращает значение элемента, если он существует и не просрочен. В противном случае
     * возвращает `null`.
     */
    get( key ) {
        const item      = this.#cache.get( key )
        const timestamp = new Date().getTime()
        if ( item && item.expire > timestamp && item.value ) {
            return item.value
        }
        return null
    }
    
    /**
     * Метод для добавления значения в кэш.
     * Устанавливает новое значение в кэш с указанным ключом и сроком годности (TTL). Если элемент с таким ключом уже
     * существует, он будет перезаписан.
     *
     * @param {string} key - Ключ для хранения элемента в кэше.
     * @param {any} value - Значение, которое нужно сохранить в кэше.
     */
    set( key, value ) {
        
        if ( this.#cache.has( key ) ) {
            this.#cache.delete( key )
        }
        
        const timestamp = new Date().getTime()
        const expire    = timestamp + this.#ttl
        
        const cacheData = {
            value,
            expire
        }
        
        this.#cache.set( key, cacheData )
    }
}

/**
 * Экземпляр класса `ApiCacheClass` для управления кэшированием данных в памяти с использованием `Map`.
 * Предназначен для временного хранения данных, связанных с API-запросами, с возможностью указания времени жизни
 * (TTL) для каждого элемента кэша.
 *
 * @type {ApiCacheClass}
 *
 * Методы:
 * - `get(key: string): any|null`:
 *   Получает значение из кэша по заданному ключу, если элемент не просрочен. Возвращает `null`, если элемент
 *   отсутствует или его срок годности истек.
 * - `set(key: string, value: any): void`:
 *   Добавляет новый элемент в кэш с указанным ключом и значением, устанавливая срок его годности (TTL).
 */
export const ApiCache = new ApiCacheClass( window._CACHE_ )
